import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

const PortfolioIndexPage = () => (
  <Layout page="work" route="portfolio">
    <Seo
        title="Portfolio Alistair Nicholas Bancroft"
        description={"View a selection of recent projects by Alistair Bancroft"}
    />
    <section data-background="purple">
        <header className="py-28 md:py-48 px-5 md:px-[2.25vw] lg:px-[3vw]">
          <h1 className="font-black font-sans leading-[14vw] md:leading-none mb-12 md:mb-24 text-[15.625vw] lg:text-[11.71875vw] xl:text-[9.375vw] text-white uppercase">Recent projects <span className="font-light text-[5vw] lg:text-[3.25vw]">12</span></h1>
          <div className="flex">
            <div className="w-full xl:w-3/5">
              <div className="font-light font-mono mb-2 text-[10px] sm:text-[1.5625vw] md:text-[1.30208333333vw] lg:text-[0.9765625vw] xl:text-[0.78125vw] text-white/80">Info</div>
              <p className="font-thin font-sans leading-snug text-[22px] sm:text-[3.4375vw] md:text-[2.86458333333vw] lg:text-[2.1484375vw] xl:text-[1.71875vw] text-white">With over 15 years of experience in the industry, numerous team awards, and a track record of successfully delivering hundreds of projects, I invite you to explore my portfolio and witness the culmination of expertise, innovation, and passion in front-end development.</p>
            </div>
          </div>
        </header>
    </section>
  </Layout>
)

export default PortfolioIndexPage
